import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";

import Navigation from "../Navigation";
import HomePage from "../HomePage";
import PrivacyPage from "../Privacy";
import TermsConditionsPage from "../Terms";
import SignInPage from "../SignIn";
import SignUpPage from "../SignUp";
import PasswordForgetPage from "../PasswordForget";
import AccountPage from "../Account";
import AdminPage from "../Admin";
import UserPage from "../User";
import MemberPage from "../Member";
import InvestorPage from "../Investor";
import DashboardPage from "../Dashboard";

import CoinsPage from "../Coins";
import CoinsCreatePage from "../Coins/CoinsCreatePage";
import CoinsEditPage from "../Coins/CoinsEditPage";
import CoinsCasePage from "../Coins/CoinsCasePage";
import CoinsCaseList from "../Coins/CoinsCaseList";
import CoinsCaseDetails from "../Coins/CoinsCaseDetails";
import CoinsAddPage from "../Coins/CoinsAddPage";
import CoinsTransferPage from "../Coins/CoinsTransferPage";

import PlansPage from "../Plans";
import PlansDripPage from "../Plans/PlansDripPage";
import PlansDripList from "../Plans/PlansDripList";
import PlansDripDetails from "../Plans/PlansDripDetails";

import NftPage from "../Nft";
import Nft721 from "../Nft/NftList721";
import Nft1155 from "../Nft/NftList1155";
import NftListInfura from "../Nft/NftListInfura";

import * as ROUTES from "../../constants/routes";
import { withAuthentication } from "../Session";

const App = () => (
  <Router>
    <Navigation />
    <Route exact path={ROUTES.HOME} component={HomePage} />
    <Route path={ROUTES.PRIVACY} component={PrivacyPage} />
    <Route path={ROUTES.TERMS} component={TermsConditionsPage} />
    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
    <Route path={ROUTES.SIGN_IN} component={SignInPage} />
    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetPage} />
    <Route path={ROUTES.DASHBOARD} component={DashboardPage} />
    <Route path={ROUTES.PLANS} component={PlansPage} />
    <Route path={ROUTES.PLANS_DRIP} component={PlansDripPage} />
    <Route path={ROUTES.PLANS_DRIP_LIST} component={PlansDripList} />
    <Route path={ROUTES.PLANS_DRIP_DETAILS} component={PlansDripDetails} />
    <Route path={ROUTES.NFT} component={NftPage} />
    <Route path={ROUTES.NFTS} component={NftListInfura} />
    <Route path={ROUTES.NFT_721} component={Nft721} />
    <Route path={ROUTES.NFT_1155} component={Nft1155} />
    <Route path={ROUTES.COINS} component={CoinsPage} />
    <Route path={ROUTES.COINS_CREATE} component={CoinsCreatePage} />
    <Route path={ROUTES.COINS_CASE} component={CoinsCasePage} />
    <Route path={ROUTES.COINS_CASE_LIST} component={CoinsCaseList} />
    <Route path={ROUTES.COINS_CASE_DETAILS} component={CoinsCaseDetails} />
    <Route path={ROUTES.COINS_CASE_TRANSFER} component={CoinsTransferPage} />
    <Route path={ROUTES.COINS_CASE_ADD} component={CoinsAddPage} />
    <Route path={ROUTES.COINS_CASE_EDIT} component={CoinsEditPage} />
    <Route path={ROUTES.USER} component={UserPage} />
    <Route path={ROUTES.MEMBER} component={MemberPage} />
    <Route path={ROUTES.INVESTOR} component={InvestorPage} />
    <Route path={ROUTES.ADMIN} component={AdminPage} />
    <Route path={ROUTES.ACCOUNT} component={AccountPage} />
  </Router>
);

export default withAuthentication(App);
