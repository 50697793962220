import React, { Component } from "react";


import CoinsCaseCoin from "./CoinsCaseCoin";
import CoinsCaseVault from "./CoinsCaseVault";
import CoinsCaseCash from "./CoinsCaseCash";
import CoinsCaseTotal from "./CoinsCaseTotal";
import CoinsCaseBullion from "./CoinsCaseBullion";

import { withFirebase } from "../Firebase";

import moment from "moment";

var myHeaders = new Headers();
myHeaders.append("x-access-token", "goldapi-1w3ukkeiqr5m-io");
myHeaders.append("Content-Type", "application/json");

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

const BullionContent = (props) => (
  <div className="container">
    <CoinsCaseBullion coin={props.coin} />
    <CoinsCaseCoin coin={props.coin} />
    <CoinsCaseVault coin={props.coin}/>
    <CoinsCaseCash coin={props.coin}/>
    <CoinsCaseTotal coin={props.coin}/>
  </div>
);

const Gold = "https://www.goldapi.io/api/XAU/USD";
const Silver = "https://www.goldapi.io/api/XAG/USD";
const Platinum = "https://www.goldapi.io/api/XPT/USD";

const INITIAL_STATE = {
  not_found: false,
  coin: null,
  validQuickPIN: false,
  quickPin: "",
  tokenCount: 1,
  error: null
};

class CoinsCaseDetailsPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchCoin(this.props.match.params.id);
  }

  fetchCoin = async (uid) => {
    console.log("CoinsCaseDetails fetchCoin", uid);
    let coin = await this.props.firebase.fetchCoin(uid);
    if (coin === null) {
      this.setState({
        loading: false,
        not_found: true,
      });
    } else {
      coin.uid = uid;
      if (coin.coin_type === "hyland_type"){
        this.hyland_coin(coin);
      } else {
        this.bullion_coin(coin);
      }
    }
  }

  fetchCoinTypes = async (coin_type) => {
    let coin_types = await this.props.firebase.fetchCoinTypes(coin_type);
    this.setState({
      coin_types,
    });
  }

  bullion_coin = async (coin) => {
    var goldValue = 0;
    await fetch(Gold, requestOptions)
    .then(response => response.json())
    .then(goldQuote => {
      goldValue = goldQuote.price;
    }).catch(error => console.log('error', error));

    var silverValue = 0;
    await fetch(Silver, requestOptions)
    .then(response => response.json())
    .then(silverQuote => {
      silverValue = silverQuote.price;
    }).catch(error => console.log('error', error));

    var platinumValue = 0;
    await fetch(Platinum, requestOptions)
    .then(response => response.json())
    .then(platinumQuote => {
      platinumValue = platinumQuote.price;
    }).catch(error => console.log('error', error));

    var quoteUrl = Gold;
    switch (coin.bullion_type) {
      case "silver":
        quoteUrl = Silver;
        break;
      case "platinum":
        quoteUrl = Platinum;
        break;
      default: // gold       
        quoteUrl = Gold;    
    }

    fetch(quoteUrl, requestOptions)
    .then(response => response.json())
    .then(quote => {
      var time = moment.unix(quote.timestamp).format("MMM Do, hh:mm a")
      var melt_value = (coin.weight_oz * quote.price).toFixed(2);
      var coin_value = (+melt_value + +coin.premium).toFixed(2);       
      //if (coin.cash_value == 0) {
      //  coin.cash_value = coin_value;
      //}
      var total_value = (+coin_value + +coin.cash_value).toFixed(2);
      coin.spot = quote.price.toFixed(2);
      coin.melt_value = melt_value;
      coin.coin_value = coin_value;
      coin.total_value = total_value;
      coin.time = time;
      coin.silver_spot = silverValue;
      coin.gold_spot = goldValue;
      coin.platinum_spot = platinumValue;
      this.setState({
        loading: false,
        coin,
      });
    }).catch(error => console.log('error', error));
  }

  hyland_coin = async (coin) => {
    this.setState({
      coin,
    });
  }

  componentWillUnmount() {
  }

  onSubmitPIN = (event) => {
    const { coin, quickPin } = this.state;
    if (coin == null) {
      const error = { message: "Invalid Coin"}
      this.setState({ error });
    } else {      
      if (quickPin === coin.quickpin) {
        this.setState({ error: null, validQuickPIN:true });
      } else {
        const error = { message: "Invalid PIN"}
        this.setState({ error, quickPin:"" });
      }
    }
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onSubmitTokens = (event) => {
    const { coin, tokenCount, button } = this.state;
    if (coin == null) {
      const error = { message: "Invalid Coin"}
      this.setState({ error });
    } else {      
      if (button === "remove") {
        let coin_value = coin.coin_value-tokenCount;
        console.log("uid", coin.uid, "coin_value", coin_value);
        this.props.firebase.coin(coin.uid).set(
        {
          coin_value,
        }, {merge: true})
        .then(() => {
          this.setState({ ...INITIAL_STATE });
        })
        .catch((error) => {
          console.log("error", error);
          this.setState({ error });        
        })              
      } else if (button === "add") {
        let coin_value = coin.coin_value+tokenCount;
        console.log("uid", coin.uid, "coin_value", coin_value);
        this.props.firebase.coin(coin.uid).set(
        {
          coin_value,
        }, {merge: true})
        .then(() => {
          this.setState({ ...INITIAL_STATE });
        })
        .catch((error) => {
          console.log("error", error);
          this.setState({ error });        
        })              
      } else {
        const error = { message: "Invalid Token Count"}
        this.setState({ error });
      }
    }
    event.preventDefault();
  };  

  onChangeTokens = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { coin, loading, not_found, quickPin, validQuickPIN, tokenCount, error } = this.state;

    const invalidPIN =
      quickPin === "";

    if ((coin === null) || (loading)) {
      return (
        <div className="container">Loading ...</div>
      )
    } else if ((coin != null) && (coin.coin_type === "hyland_type")) {
      return (
          <div className="container">
              <span className="font-weight-bold my-2">Wallet Value</span><br />
              <span>Tokens: {coin.coin_value}&nbsp;&nbsp;</span><br/>
              <span>Cash: ${coin.cash_value*coin.coin_value}&nbsp;</span><br/>
              { (coin.owner != null) && (coin.owner.name != null) && (!validQuickPIN) && ( 
                  <div>
                    <form onSubmit={this.onSubmitPIN}>
                      <div className="form-group">
                        <input
                          id="inputPIN"
                          className="inputPIN"
                          name="quickPin"
                          value={quickPin}
                          onChange={this.onChange}
                          pattern="[0-9]{4,8}"
                          minLength="4"
                          maxLength="8"
                          type="password"
                          placeholder="PIN"
                        />
                        <button className="btn btn-sm btn-primary m-2" disabled={invalidPIN} type="submit">Unlock</button>
                        {error && <p className="text-danger">{error.message}</p>}
                      </div>
                    </form>
                  </div>
              ) } 
              { validQuickPIN && (
                <div>
                    <form onSubmit={this.onSubmitTokens}>
                      <div className="form-group">
                        <button className="btn btn-sm btn-outline-primary my-2" onClick={()=>(this.state.button="add")} type="submit">Add Tokens</button>
                        &nbsp;&nbsp;
                        <button className="btn btn-sm btn-outline-primary my-2" onClick={()=>(this.state.button="remove")} type="submit">Remove Tokens</button>
                        <br/>
                        <input
                          id="inputTokenCount"
                          className="inputPIN"
                          name="tokenCount"
                          value={tokenCount}
                          onChange={this.onChange}
                          pattern="[0-9]{1,2}"
                          type="text"
                        />
                        {error && <p className="text-danger">{error.message}</p>}
                      </div>
                    </form>
                </div> 
              ) } 
          </div>
      );
    } else if (coin != null) {
      return (
        <BullionContent coin={coin} />
      );
    } else if ((coin === null) && (not_found)) {
      return (
        <div className="container">Coin not found.</div>
      )
    }
  }
}

export default withFirebase(CoinsCaseDetailsPage);
