import React from "react";

import { withFirebase } from "../Firebase";

const SignOutLink = ({ firebase }) => (
  <a className="mx-2" href="/Signin" onClick={firebase.doSignOut}>
    Sign Out
  </a>
);

export default withFirebase(SignOutLink);
