import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import axios from 'axios';

//const Web3 = require('web3');
//const web3 = new Web3(new Web3.providers.HttpProvider('https://mainnet.infura.io/v3/YOUR-INFURA-PROJECT-ID'));
//var web3 = new Web3(new Web3.providers.HttpProvider());
//var version = web3.version.api;

//const etherscanContractABI = "https://api.etherscan.io/api?module=contract&action=getabi&address=0xBB9bc244D798123fDe783fCc1C72d3Bb8C189413&apikey=YourApiKeyToken";

//const contractABI = [...]; // The contract ABI (Application Binary Interface)
//const contractAddress = '0x...'; // The contract address

//const contract = new web3.eth.Contract(contractABI, contractAddress);

const INITIAL_STATE = {
  loading: false,
  nfts: [],
  limit: 50,
  orderBy: "weight_oz",
};

class NftListInfura extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchNft(this.props.match.params.id);
  }

  fetchNft = async (address) => {
    this.setState({ nfts: [], loading: true });
    const { limit, orderBy } = this.state;

    // the coin owner property is the logged in user's uid
    console.log("NftList fetchNft", address);

    try {
      var url = "https://api.etherscan.io/api?module=account&action=tokennfttx&address=" + address + "&page=1&offset=100&startblock=0&endblock=27025780&sort=asc&apikey=3FM4XMHXFFFAY25K2Y6APH6MAC2GZBND9N";
      const res = await axios.get(url, {
        headers: {},
        params: {}
      });
      console.log("nfts", res);

      this.setState({
        loading: false,
        nfts: res.data.result
      });

    } catch (err) {
      console.log(err);
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentWillUnmount() {
  }
  
  render() {
    const { nfts, loading } = this.state;

    var srcImg = "https://i.seadn.io/gcs/";

    return (
      <div  id="CoinsCaseList" className="tab-pane active">

        <h3 className="mx-4">My NFT</h3>
        {loading && <div className="mx-4">Loading ...</div>}
        {!loading && (nfts.length === 0) && (
          <div className="mx-4">No coins found</div>
        )}
        {nfts.map((nft) => (
          <dl className="mx-4 border-bottom" key={nft.tokenID}>
          <dt>
            <span className="font-weight-heavy">{nft.tokenName}</span>&nbsp;<span className="font-weight-light">{nft.tokenSymbol}</span><br/>
            <span className="font-weight-light">{nft.tokenID}</span><br/>
            <span className="font-weight-light">{nft.contractAddress}</span><br/>
            <span className="font-weight-light">{nft.description}</span><br/>
            <span><img width="200px" src={nft.imageUrl}></img></span><br/>            
            <></>
          </dt>
        </dl>
      ))}

      </div>
    );
  }
}

export default withFirebase(NftListInfura);
