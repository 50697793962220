import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const INITIAL_STATE = {
  uid: "",
  name: "",
  description: "",
  bullion_purity: 0,
  bullion_type: "",
  buyback: 0,
  mint: "",
  image_front: "",
  premium: 0,
  url: "",
  weight_oz: 0,
  error: null
};

class CoinsCreatePage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
  }

  componentWillUnmount() {
  }

  onSaveCoin = (event) => {
    const { uid, name, bullion_purity, bullion_type, description, buyback, mint, image_front, premium, url, weight_oz } = this.state;
    this.props.firebase.createCoinProduct(uid, name, bullion_purity, bullion_type, description, buyback, mint, image_front, premium, url, weight_oz);
    this.props.history.push(ROUTES.COINS_CASE);
    event.preventDefault();
  };    

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  render() {
    const { uid, name, description, bullion_purity, bullion_type, buyback, mint, image_front, premium, url, weight_oz, error } = this.state;

    if (error) {
      return (
        <div className="container">Error: {error}</div>
      )
    }
     else {
      return (
        <div className="container">
          <h4>Coin Product</h4>
          <div>
            <form onSubmit={this.onSaveCoin}>
              <div className="form-group">
              <label htmlFor="inputUID">UID</label>&nbsp;
                <input
                  id="inputUID"
                  className="inputUID"
                  name="uid"
                  value={uid}
                  onChange={this.onChange}
                  placeholder="<type>-<nickname>-<weight>"
                /><br/>
                <label htmlFor="inputName">Name</label>&nbsp;
                <input
                  id="inputName"
                  className="inputName"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  placeholder="<type> <weight> oz. (<nickname>)"
                /><br/>
                <label htmlFor="inputDescription">Description</label>&nbsp;
                <input
                  id="inputDescription"
                  className="inputDescription"
                  name="description"
                  value={description}
                  onChange={this.onChange}
                  placeholder="<type> <nickname>"
                /><br/>
                <label htmlFor="inputbullion_type">Bullion Type</label>&nbsp;
                <input
                  id="inputbullion_type"
                  className="inputbullion_type"
                  name="bullion_type"
                  value={bullion_type}
                  onChange={this.onChange}
                  placeholder="<gold/silver/platinum>"
                /><br/>
                <label htmlFor="inputbullion_purity">Bullion Purity</label>&nbsp;
                <input
                  id="inputbullion_purity"
                  className="inputbullion_purity"
                  name="bullion_purity"
                  value={bullion_purity}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputbuyback">Buyback</label>&nbsp;
                <input
                  id="inputbuyback"
                  className="inputbuyback"
                  name="buyback"
                  value={buyback}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputmint">Mint</label>&nbsp;
                <input
                  id="inputmint"
                  className="inputmint"
                  name="mint"
                  value={mint}
                  onChange={this.onChange}
                  placeholder="<mint name>"
                /><br/>
                <label htmlFor="inputimage_front">Image Front</label>&nbsp;
                <input
                  id="inputimage_front"
                  className="inputimage_front"
                  name="image_front"
                  value={image_front}
                  onChange={this.onChange}
                  placeholder="<url>"
                /><br/>
                <label htmlFor="inputpremium">Premium</label>&nbsp;
                <input
                  id="inputpremium"
                  className="inputpremium"
                  name="premium"
                  value={premium}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputurl">URL</label>&nbsp;
                <input
                  id="inputurl"
                  className="inputurl"
                  name="url"
                  value={url}
                  onChange={this.onChange}
                  placeholder="<wikipedia url>"
                /><br/>
                <label htmlFor="inputweight_oz">Weight (oz.)</label>&nbsp;
                <input
                  id="inputweight_oz"
                  className="inputweight_oz"
                  name="weight_oz"
                  value={weight_oz}
                  onChange={this.onChange}
                  placeholder="<0.88>"
                /><br/>
                <button className="btn btn-sm btn-primary m-2" type="submit">Save Changes</button>
                {error && <p className="text-danger">{error.message}</p>}
              </div>
            </form>
          </div>
        </div>
      )
    }
  }
}

export default withFirebase(CoinsCreatePage);
