import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

var myHeaders = new Headers();
myHeaders.append("x-access-token", "goldapi-1w3ukkeiqr5m-io");
myHeaders.append("Content-Type", "application/json");

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

const Gold = "https://www.goldapi.io/api/XAU/USD";
const Silver = "https://www.goldapi.io/api/XAG/USD";
const Platinum = "https://www.goldapi.io/api/XPT/USD";

const INITIAL_STATE = {
  loading: false,
  cases: [],
  purchase: 0,
  wholesale: 0,
  retail: 0,
  profit: 0,
  limit: 500,
  orderBy: "weight_oz",
};

class CoinsCaseList extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchCoins();
  }

  fetchCoins = async () => {
    console.log("CoinsCaseList fetchCoins");
    this.setState({ cases: [], loading: true });
    const { limit, orderBy } = this.state;

    //this.props.firebase.auth.currentUser.uid);
    const ownerId = this.props.userId === null ? "unassigned" : this.props.userId;

    var goldValue = 0;
    await fetch(Gold, requestOptions)
      .then(response => response.json())
      .then(goldQuote => {
        goldValue = goldQuote.price;
      }).catch(error => console.log('error', error));

    var silverValue = 0;
    await fetch(Silver, requestOptions)
      .then(response => response.json())
      .then(silverQuote => {
        silverValue = silverQuote.price;
      }).catch(error => console.log('error', error));

    var platinumValue = 0;
    await fetch(Platinum, requestOptions)
      .then(response => response.json())
      .then(platinumQuote => {
        platinumValue = platinumQuote.price;
      }).catch(error => console.log('error', error));    

    // the coin owner property is the logged in user's uid
    this.props.firebase.coins()
      .where("owner", "==", ownerId)
      .orderBy(orderBy)
      .limit(limit)
      .get()
      .then((snapshot) => {
        let cases = [];
        let purchase = 0;
        let wholesale = 0;
        let retail = 0;
        let profit = 0;

        snapshot.forEach((doc) => {
          var coin = doc.data();
          var quotePrice = goldValue;
          switch (coin.bullion_type) {
            case "silver":
              quotePrice = silverValue;
              break;
            case "platinum":
              quotePrice = platinumValue;
              break;
            default: // gold       
            quotePrice = goldValue;    
          }

          coin.uid = doc.id;
          var melt_value = (coin.weight_oz * quotePrice).toFixed(2);
          var coin_value = (+melt_value + +coin.premium).toFixed(2);
          coin.coin_value = coin_value;
          var coin_buyback = (coin_value - coin.buyback).toFixed(2);
          coin.coin_buyback = coin_buyback;
          purchase = (+purchase + +coin.purchase_price).toFixed(2);
          wholesale = (+wholesale + +coin_buyback).toFixed(2);
          retail = (+retail + +coin_value).toFixed(2);
          // add to list
          cases.push({ ...coin, uid: doc.id })
        });

        profit = (retail - purchase).toFixed(2);

        this.setState({
          cases,
          purchase,
          wholesale,
          retail,
          profit,
          loading: false,
        });
      });
  }
  
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentWillUnmount() {
  }
  
  render() {
    const { cases, purchase, wholesale, retail, profit, loading } = this.state;

    return (
      <div  id="CoinsCaseList" className="tab-pane active">

        <h3 className="mx-4">My Coins</h3>
        {loading && <div className="mx-4">Loading ...</div>}
        {!loading && (cases.length === 0) && (
          <div className="mx-4">No coins found</div>
        )}
        {!loading && (cases.length > 0) && (
          <p>
            <span className="mx-4">Retail Value: ${retail}</span>&nbsp;
            <span>Purchase Price: ${purchase}</span>&nbsp;&nbsp;
            <span className="font-weight-bold">Profit: ${profit}</span>&nbsp;&nbsp;
            <span>(trade-in value: ${wholesale})</span>&nbsp;
          </p>
        )}
        {cases.map((coin) => (
          <dl className="mx-4 border-bottom" key={coin.uid}>
            <dt>
              <span>{coin.description}</span>
              &nbsp;<span className="font-weight-light">{coin.weight_oz}&nbsp;oz. {coin.bullion_type}
                ,&nbsp;
                retail: ${coin.coin_value}        
                ,&nbsp;
                purchase: ${coin.purchase_price}        
                ,&nbsp;
                (trade-in: ${coin.coin_buyback})
                &nbsp;
                <Link
                    to={{
                      pathname: `${ROUTES.COINS_CASE}/${coin.uid}`,
                      state: { coin },
                    }}
                  >
                    Details
                </Link>
                </span>
            </dt>
          </dl>
        ))}
      </div>
    );
  }
}

export default withFirebase(CoinsCaseList);
