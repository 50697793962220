import React from "react";
import { Link } from "react-router-dom";

import * as ROUTES from "../../constants/routes";

const CoinsPage = () => (
  <div>
    <ul className="nav nav-pills nav-fill border-bottom mb-2">
      <li className="nav-item font-weight-bold">
        <Link className="nav-link active" to={ROUTES.COINS_CASE}>Available Coins for Purchase</Link>
      </li>
    </ul>
  </div>
);

export default CoinsPage;
