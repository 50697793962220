import React, { Component } from "react";
import { withFirebase } from "../Firebase";

var myHeaders = new Headers();
myHeaders.append("x-access-token", "goldapi-1w3ukkeiqr5m-io");
myHeaders.append("Content-Type", "application/json");

const INITIAL_STATE = {
  coin: null,
};

class PlansDripDetails extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
  }

  onSubmit = event => {
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  componentWillUnmount() {
  }
  
  render() {
    const { coin } = this.state;
    if (coin === null) {
      return (
        <div className="container">Loading ...</div>
      )
    } else {
      return (
        <div className="container">
          Stuff
        </div>
      );        
    }
  }
}

export default withFirebase(PlansDripDetails);
