import React from "react";

const Body = () => (
  <section class="page-section text-center" id="about">
    <div class="container-fluid">
      <h1 class="page-section-heading text-uppercase">ABOUT OUR COINS</h1>
      <p class="lead">99.9% Pure Bullion Coins</p>
      <hr class="primary small mb-5"></hr>
      <div class="row">
        <div class="col-md-6 col-xl-3 px-md-5 mb-4 mb-xl-0">
          <i class="fas fa-coins fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Coins</h4>
          <p>in gold or silver.</p>
        </div>
        <div class="col-md-6 col-xl-3 px-md-5 mb-4 mb-xl-0">
          <i class="fas fa-search-dollar fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Coin Values</h4>
          <p>via instant quotes.</p>
        </div>
        <div class="col-md-6 col-xl-3 px-md-5 mb-4 mb-xl-0">
          <i class="fas fa-university fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Store Coins</h4>
          <p>via secure vault.</p>
        </div>
        <div class="col-md-6 col-xl-3 px-md-5">
          <i class="fas fa-hand-holding-usd fa-4x mb-3"></i>
          <h4 class="text-uppercase text-muted">Spend</h4>
          <p>via debit card.<br />
          </p>
        </div>
      </div>
    </div>
  </section>
);

export default Body;
