import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const PasswordForgetPage = () => (
  <div className="p-1">
    <h2>Password Reset</h2>
    <PasswordForgetForm />
  </div>
);

const INITIAL_STATE = {
  email: "",
  error: null
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email } = this.state;

    this.props.firebase
      .doPasswordReset(email)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email, error } = this.state;

    const isInvalid = email === "";

    return (
      <form onSubmit={this.onSubmit}>
        <div className="input-group p-1">
          <div className="input-group-prepend">
            <label className="input-group-text" htmlFor="inputEmail">
              Update
            </label>
          </div>
          <input
            className="form-control"
            id="inputEmail"
            name="email"
            value={this.state.email}
            onChange={this.onChange}
            type="text"
            placeholder="Email"
          />
          <div className="input-group-append">
            <button
              className="btn btn-primary btn-sm"
              disabled={isInvalid}
              type="submit"
            >
              SEND EMAIL
            </button>
          </div>
        </div>
        {error && <p className="mt-3 text-danger">{error.message}</p>}
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p className="mx-3 mb-0">
    <Link to={ROUTES.PASSWORD_FORGET}>Forgot Password?</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = withFirebase(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };
