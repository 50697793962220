export const HOME = "/";
export const SIGN_UP = "/Signup";
export const SIGN_IN = "/Signin";
export const PASSWORD_FORGET = "/Pw-forget";
export const ACCOUNT = "/Account";
export const USER = "/User";
export const MEMBER = "/Member";
export const INVESTOR = "/Investor";
export const ADMIN = "/Admin";
export const ADMIN_DETAILS = "/Admin/:id";
export const PRIVACY = "/Privacy";
export const TERMS = "/Terms";
export const DASHBOARD = "/Dashboard";
export const NFT = "/Nft";
export const NFTS = "/Nfts";
export const NFT_721 = "/Nft/721/:id";
export const NFT_1155 = "/Nft/1155/:id";
export const COINS = "/Coins";
export const COINS_ADD = "/Coins/Add/";
export const COINS_CREATE = "/Coins/Create/";
export const COINS_EDIT = "/Coins/Edit/";
export const PLANS = "/Plans";
export const PLANS_DRIP = "/Plans/Drip";
export const PLANS_DRIP_LIST = "/Plans/Drip?bullion=:bullionType";
export const PLANS_DRIP_DETAILS = "/Plans/Drip/:id";
export const COINS_CASE = "/Coins/Case";
export const COINS_CASE_LIST = "/Coins/Case?product=:productId";
export const COINS_CASE_DETAILS = "/Coins/Case/:id";
export const COINS_CASE_ADD = "/Coins/Add/:id";
export const COINS_CASE_EDIT = "/Coins/Edit/:id";
export const COINS_CASE_TRANSFER = "/Coins/Transfer/:id";
export const PAYMENTS_ADD_BANK = "/Payments/Add-Bank";
export const PAYMENTS_BENEFICIAL_OWNERS = "/Payments/Beneficial-Owners";
export const PAYMENTS_BUSINESS_VERIFY = "/Payments/Business-Verify";
export const PAYMENTS_CREATE_CUSTOMER = "/Payments/Create-Customer";
export const PAYMENTS_DISPLAY_BALANCE = "/Payments/Display-Balance";
export const PAYMENTS_PAY_IN = "/Payments/Pay-In";
export const PAYMENTS_PERSON_VERIFY = "/Payments/Person-Verify";
export const PAYMENTS_UPLOAD_DOCUMENT = "/Payments/Upload-Document";
export const PAYMENTS_UPGRADE_CUSTOMER = "/Payments/Upgrade-Customer";

