import React, { Component } from "react";

import * as ROLES from "../../constants/roles";

const INITIAL_STATE = {
    error: null,
  };
 
class UpgradeInfo extends Component {
  constructor(props) {
    super(props);
    this.state = { ...INITIAL_STATE };
  }

  render() {

    if (this.props.roles[ROLES.INVESTOR]) {
      return (
        <div className="form-group mx-2">
          <p>Account type: <b>INVESTOR</b><br/><br/>Your next step is to attach a funding source.<br/>This will allow you to send money.</p>
          <a href="/Funding/">
            <button className="btn btn-primary btn-sm">Attach Funding Source</button>
          </a>
        </div>          
      );
    } else if (this.props.roles[ROLES.MEMBER]) {
        return (
        <div className="form-group mx-2">
          <p>Account type: <b>MEMBER</b><br/><br/>Your next step is to become an <b>INVESTOR</b>.<br/>This will allow you to send/receive money, and holds funds in a digital wallet.</p>
          <a href="/Investor">
            <button className="btn btn-primary btn-sm">Become an Investor</button>
          </a>
          <br/><br/>
          <p>This will allow you to deposit recieved funds.</p>
          <a href="/Deposit/">
            <button className="btn btn-primary btn-sm">Attach Deposit Destination</button>
          </a>
        </div>          
      );
    } else if (this.props.roles[ROLES.USER]) { 
      return (
        <div className="form-group mx-2">
          <p>Account type: <b>USER</b><br/><br/>Your next step is to become a <b>MEMBER</b>.<br/>This will allow you to receive money and desposit into an attached bank account.</p>
          <a href="/Member">
            <button className="btn btn-primary btn-sm">Become a Member</button>
          </a>
      </div>          
      );
    }
  }
}
    
  export default UpgradeInfo;