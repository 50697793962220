import React from "react";

const CoinsCaseCash = (props) => (
    <p>
        <span className="font-weight-bold">Wallet Cash</span>
        <br />
        <span>${props.coin.cash_value}&nbsp;<button className="btn btn-sm btn-outline-primary">Add</button></span>
    </p>    
);

export default CoinsCaseCash;
