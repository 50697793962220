import React, { Component } from "react";
import { withFirebase } from "../Firebase";

class UserItem extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      user: null,
      ...props.location.state
    };
  }

  componentDidMount() {
    if (this.state.user) {
      return;
    }

    this.setState({ loading: true });

    this.unsubscribe = this.props.firebase
      .user(this.props.match.params.id)
      .onSnapshot(snapshot => {
        this.setState({
          user: snapshot.data(),
          loading: false
        });
      });
  }

  componentWillUnmount() {
    this.unsubscribe && this.unsubscribe();
  }

  onSendPasswordResetEmail = () => {
    this.props.firebase.doPasswordReset(this.state.user.email);
  };

  render() {
    const { user, loading } = this.state;

    return (
      <div className="p-1">
        <h3>User</h3>

        {loading && <div>Loading ...</div>}

        {user && (
          <dl className="mx-4 border-bottom">
            <dt>
              <span className="text-info">{user.name}
                &nbsp;&nbsp;<small>({user.uid})</small>
              </span>
            </dt>
            <dd>
              {user.email}
              &nbsp;&nbsp;<a href="#" onClick={this.onSendPasswordResetEmail}>Send Password Reset
              </a>
            </dd>
          </dl>
        )}
      </div>
    );
  }
}

export default withFirebase(UserItem);
