import React, { Component } from "react";
import { Link } from "react-router-dom";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import PaypalCheckout from "../Paypal/PaypalCheckout";

var myHeaders = new Headers();
myHeaders.append("x-access-token", "goldapi-1w3ukkeiqr5m-io");
myHeaders.append("Content-Type", "application/json");

var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

const Gold = "https://www.goldapi.io/api/XAU/USD";
const Silver = "https://www.goldapi.io/api/XAG/USD";
const Platinum = "https://www.goldapi.io/api/XPT/USD";

const INITIAL_STATE = {
  loading: false,
  silverValue: 0,
  goldValue: 0,
  platinumValue: 0,
  products: [],
  showPaypal: false,
  orderTotal: 0,
  admin: false
};

class CoinsCaseProduct extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {    
    if (this.props.authUser) {
      this.setState({
        admin: this.props.authUser.roles.ADMIN 
      });  
    }

    this.fetchCoinProducts();
  }

  fetchCoinProducts = async () => {
    console.log("CoinsCaseProduct fetchCoinProducts");
    this.setState({ loading: true });

    var goldValue = 0;
    await fetch(Gold, requestOptions)
      .then(response => response.json())
      .then(goldQuote => {
        goldValue = goldQuote.price;
      }).catch(error => console.log('error', error));

    var silverValue = 0;
    await fetch(Silver, requestOptions)
      .then(response => response.json())
      .then(silverQuote => {
        silverValue = silverQuote.price;
      }).catch(error => console.log('error', error));

    var platinumValue = 0;
    await fetch(Platinum, requestOptions)
      .then(response => response.json())
      .then(platinumQuote => {
        platinumValue = platinumQuote.price;
      }).catch(error => console.log('error', error));      

    this.props.firebase.coin_product()
      .orderBy("bullion_type", "desc")
      .orderBy("weight_oz")
      .get()
      .then((snapshot) => {
        let products = [];

        snapshot.forEach((doc) => {
          var category = doc.data();
          var quotePrice = goldValue;
          switch (category.bullion_type) {
            case "silver":
              quotePrice = silverValue;
              break;
            case "platinum":
              quotePrice = platinumValue;
              break;
            default: // gold       
            quotePrice = goldValue;    
          }

          var melt_value = (category.weight_oz * quotePrice).toFixed(2);
          var coin_value = (+melt_value + +category.premium).toFixed(2);
          category.coin_value = coin_value;
          var coin_buyback = (coin_value - category.buyback).toFixed(2);
          category.coin_buyback = coin_buyback;
          // add to list
          products.push({ ...category, uid: doc.id })
        });

        this.setState({
          products,
          silverValue,
          goldValue,
          platinumValue,
          loading: false,
        });
      });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  calculateTotal = orderTotal => {
    this.setState({
      orderTotal,
      showPaypal: true,
    });
  }

  closePayment = () => {
    this.setState({
      showPaypal: false,
    });
  }

  render() {
    const { admin, showPaypal, orderTotal, products, silverValue, goldValue, platinumValue, loading } = this.state;

    if (showPaypal) {
      return <PaypalCheckout orderTotal={orderTotal} closePayment={this.closePayment} />
    } else {
      return (
        <div id="CoinsCaseProduct" className="tab-pane active">
          <p className="mx-4">Silver&nbsp;${silverValue}&nbsp;per&nbsp;oz.&nbsp;&bull; Gold&nbsp;${goldValue}&nbsp;per&nbsp;oz.&nbsp;&bull; Platinum&nbsp;${platinumValue}&nbsp;per&nbsp;oz.</p>
          {loading && <div>Loading ...</div>}
          <p><Link className="mx-4"
            to={{
              pathname: `${ROUTES.COINS_CASE}/aqfxzcHZdDxvxRbCK4ft`,
            }}
          >Free Dashboard<small>&nbsp;(example page)</small></Link></p>
          <p>
            { admin && <Link className="mx-4" to={{ pathname: `${ROUTES.COINS_CREATE}`, }}>Add Product</Link> }
          </p>
          {products.map((product) => (
            <dl className="mx-4 border-bottom" key={product.uid}>
              <dt>
                <span>{product.name}&nbsp;</span>
                <button
                  className="btn btn-sm btn-primary"
                  data-toggle="modal"
                  data-target={"#checkout-modal"}
                  onClick={() => this.calculateTotal(product.coin_value)}>
                  Buy&nbsp;${product.coin_value}
                </button>
                { admin && <Link className="mx-1 font-weight-light" to={{ pathname: `${ROUTES.COINS_EDIT+product.uid}`}}>Edit Product</Link> }
                { admin && <Link className="mx-1 font-weight-light" to={{ pathname: `${ROUTES.COINS_ADD+product.uid}`, }}>Add Coin</Link> }
              </dt>
            </dl>
          ))}

        </div>
      );
    }
  }
}

export default withFirebase(CoinsCaseProduct);

// <small>&nbsp;(sell&nbsp;back&nbsp;${product.coin_buyback})&nbsp;</small>
