import React from "react";

import Footer from "./Footer";
import Body from "./Body";
import Header from "./Header";

class HomePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <div>
        <Header/>
        <Body/>
        <Footer/>
      </div>
    );
  }
}

export default HomePage;
