import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

import moment from "moment";

var myHeaders = new Headers();
myHeaders.append("x-access-token", "goldapi-1w3ukkeiqr5m-io");
myHeaders.append("Content-Type", "application/json");


var requestOptions = {
  method: 'GET',
  headers: myHeaders,
  redirect: 'follow'
};

const Gold = "https://www.goldapi.io/api/XAU/USD";
const Silver = "https://www.goldapi.io/api/XAG/USD";
const Platinum = "https://www.goldapi.io/api/XPT/USD";

const INITIAL_STATE = {
  phoneNumber: null,
  newOwner: null,
  loading: false,
  coin: null,
  error: null
};

class CoinsTransferPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchCoinWithOwner(this.props.match.params.id);
  }

  fetchCoinWithOwner = async (uid) => {
    console.log("CoinTransferPage fetchCoinWithOwner", uid);
    let coin = await this.props.firebase.fetchCoinWithOwner(uid);
    if (coin === null) {
      this.setState({
        loading: false,
      });
    } else {
      coin.uid = uid;
      this.bullion_coin(coin);
    }
  }

  bullion_coin = async (coin) => {
    var goldValue = 0;
    await fetch(Gold, requestOptions)
    .then(response => response.json())
    .then(goldQuote => {
      goldValue = goldQuote.price;
    }).catch(error => console.log('error', error));

    var silverValue = 0;
    await fetch(Silver, requestOptions)
    .then(response => response.json())
    .then(silverQuote => {
      silverValue = silverQuote.price;
    }).catch(error => console.log('error', error));
   
    var quoteUrl = Gold;
    switch (coin.bullion_type) {
      case "silver":
        quoteUrl = Silver;
        break;
      case "platinum":
        quoteUrl = Platinum;
        break;
      default: // gold       
        quoteUrl = Gold;    
    }

    fetch(quoteUrl, requestOptions)
    .then(response => response.json())
    .then(quote => {
      var time = moment.unix(quote.timestamp).format("MMM Do, hh:mm a")
      var melt_value = (coin.weight_oz * quote.price).toFixed(2);
      var coin_value = (+melt_value + +coin.premium).toFixed(2);       
      //if (coin.cash_value == 0) {
      //  coin.cash_value = coin_value;
      //}
      var total_value = (+coin_value + +coin.cash_value).toFixed(2);
      coin.spot = quote.price.toFixed(2);
      coin.melt_value = melt_value;
      coin.coin_value = coin_value;
      coin.total_value = total_value;
      coin.time = time;
      coin.silver_spot = silverValue;
      coin.gold_spot = goldValue;
      this.setState({
        loading: false,
        coin,
      });
    }).catch(error => console.log('error', error));
  }  

  componentWillUnmount() {
  }

  onFindOwner = (event) => {
    const { coin, phoneNumber } = this.state;
    if (coin == null) {
      const error = { message: "Invalid coin."}
      this.setState({ error });
    } else if (this.props.firebase.auth.currentUser == null) {
      const error = { message: "Please login."}
      this.setState({ error });
    } else if (phoneNumber == null) {
      const error = { message: "Please enter valid phone."}
      this.setState({ error });
    } else {
      console.log("fetchUser", phoneNumber);
      this.props.firebase.fetchUser(phoneNumber)
      .then(doc => {
        console.log("doc", doc);
        if (doc.length === 1) {
          this.setState({ error: null, newOwner:doc[0] });
        } else {
          const error = { message: "Could not find new owner."}
          this.setState({ error, phoneNumber: "", newOwner:null });
        }
        });
    }
    event.preventDefault();
  };  

  onConfirmTransfer = (event) => {
    const { coin, newOwner } = this.state;
    if (coin == null) {
      const error = { message: "Invalid coin."}
      this.setState({ error });
    } else if (this.props.firebase.auth.currentUser == null) {
      const error = { message: "Please login."}
      this.setState({ error });
    } else if (newOwner !=  null) {      
      this.props.firebase.assignOwner(this.props.firebase.auth.currentUser.uid, coin.uid, newOwner.uid);
      this.setState({ error: "", phoneNumber: "", newOwner: null });
      this.props.history.push(ROUTES.DASHBOARD);  
    }
    event.preventDefault();
  };    

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  render() {
    const { coin, loading, phoneNumber, newOwner, error } = this.state;

    if ((coin === null) || (loading)) {
      return (
        <div className="container">Login required, please wait while we confirm...</div>
      )
    } else if ((coin != null) && (newOwner === null)) {
      return (
        <div className="container">
          <span>Coin:&nbsp;{coin.uid}</span><br/>
          <span className="font-weight-bold">Value:&nbsp;${coin.total_value}</span>
          <div>
            <form onSubmit={this.onFindOwner}>
              <div className="form-group">
                <input
                  id="inputPhoneNumber"
                  className="inputPhoneNumber"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={this.onChange}
                  placeholder="Phone (18012345678)"
                />
                <button className="btn btn-sm btn-primary m-2" type="submit">Find New Owner</button>
                {error && <p className="text-danger">{error.message}</p>}
              </div>
            </form>
          </div>
        </div>
      )
    } else if ((coin != null) && (newOwner != null)) {
      return (
        <div className="container">
          <span>Coin:&nbsp;{coin.uid}</span><br/>
          <span>Value:&nbsp;${coin.total_value}</span><br/>
          <span className="font-weight-bold">New Owner:&nbsp;{newOwner.uid}</span>
            <form onSubmit={this.onConfirmTransfer}>
              <div className="form-group">
                <button className="btn btn-sm btn-primary m-2" type="submit">Confirm Transfer</button>
                {error && <p className="text-danger">{error.message}</p>}
              </div>
            </form>
        </div>
      )
    }
  }
}

export default withFirebase(CoinsTransferPage);
