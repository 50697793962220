import React from 'react';

import AuthUserContext from './context';
import { withFirebase } from '../Firebase';

const needsEmailVerification = authUser =>
  authUser &&
  !authUser.emailVerified &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => this.setState({ isSent: true }));
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            needsEmailVerification(authUser) ? (
              <div className='m-2'>
                {this.state.isSent ? (
                  <div>
                    <h6>Email Confirmation Sent</h6>
                    <p>
                      Check your email (Spam
                      folder included) for a confirmation email.
                      Refresh this page once you confirmed your email.
                    </p>
                  </div>
                ) : (
                  <div>
                    <h6>Verify Your Email</h6>
                    <p>
                      Check your email (Spam folder
                      included) for a confirmation email or send
                      another confirmation email.
                    </p>
                  </div>
                )}

                <button
                  className="mt-2 btn btn-primary btn-lg"
                  type="button"
                  onClick={this.onSendEmailVerification}
                  disabled={this.state.isSent}
                >
                  Send confirmation email
                </button>
              </div>
            ) : (
              <Component {...this.props} />
            )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
