import React from "react";

import * as ROUTES from "../../constants/routes";

const CoinsCaseVault = (props) => (
    <p>
        <span className="font-weight-bold">Bullion Vault</span>
        <br />
        <span>0.0 oz. silver ${props.coin.cash_value}</span>&nbsp;and
        <br/>
        <span> 0.0 oz. gold ${props.coin.cash_value}</span>
        <span> = $0.00
            &nbsp;<a href={ROUTES.PLANS_DRIP} className="btn btn-sm btn-outline-primary">Add</a>            
        </span>
    </p>    
);

export default CoinsCaseVault;
