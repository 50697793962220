import React, { Component } from "react";
import { withFirebase } from "../Firebase";
import QRCode from "qrcode";

var myHeaders = new Headers();
myHeaders.append("x-access-token", "goldapi-1w3ukkeiqr5m-io");
myHeaders.append("Content-Type", "application/json");

const INITIAL_STATE = {
  loading: true,
  coin_product: "",
  purchase_price: "",
  product: null,
  mint_year: "",
  coin_id: "",
  coin_url: "",
  qr_code: null,
  error: null
};

class CoinsAddPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.setCoinProduct(this.props.match.params.id);
  }

  setCoinProduct = async (coin_product) => {
    console.log("CoinAddPage coin_product", coin_product);

    let product = await this.props.firebase.fetchProduct(coin_product);
    if (product != null) {
      this.setState({
        loading: false,
        product: product,        
        coin_product: coin_product,        
      });
    } else {
      this.setState({
        coin_product: coin_product,        
      });
    }
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentWillUnmount() {
  }

  onAddCoin = async (event) => {
    const { product, coin_product, mint_year, purchase_price } = this.state;
    if ((product !== null) && (coin_product != "")) {
      if (mint_year === "") {
        const error = { message: "Please enter mint year."}
        this.setState({ error });
      } else {
        console.log("addCoin", mint_year);

        try {
          this.props.firebase.db.collection("coins").add({
            bullion_purity: product.bullion_purity,
            bullion_type: product.bullion_type,
            cash_value: 0,
            coin_product: coin_product,
            description: product.description,
            image_front: product.image_front,
            mint: product.mint,
            mint_year: mint_year,
            nfc_id: "",
            owner: "lLfj5r73fZRROfYmC0L7smNBaxG2",
            premium: product.premium,
            printed: false,        
            purchase_price: parseFloat(purchase_price),
            url: product.url,
            weight_oz: product.weight_oz,
          })
          .then((docRef) => {
            let coin_id = docRef.id;
            let coin_url = 'https://switchpay.cash/Coins/Case/'+ coin_id;
            console.log(coin_url);

            QRCode.toDataURL(coin_url, { width: 300 })
            .then(dataURL => {
              this.setState({ error: null, coin_id: docRef.id, coin_url, qr_code: dataURL });  
            })
            .catch(error => {
              console.log("error" + error);
            });
          })
          .catch(function (error) {
              console.log("error" + error);
          });
        } catch (error) {
          console.log("error" + error);
        }
      }
    } 

    event.preventDefault();
  };     

  render() {
    const { loading, qr_code, coin_id, coin_url, product, mint_year, purchase_price, error } = this.state;

    if ((product === null) || (loading)) {
      return (
        <div className="container">Loading...</div>
      )
    } else if (coin_id !== "") {

      return (
        <div className="container">
          <span>New Coin ID: {coin_id}</span>&nbsp;<button className="btn btn-sm btn-primary" onClick={() =>  navigator.clipboard.writeText(coin_id)}>copy</button><br/>
          <span>Coin page: <a target="_blank" rel="noreferrer" href={coin_url}>click here</a></span><br/>
          <span>Mint Year: {mint_year}</span><br/>
          <span>Purchase Price: ${purchase_price}</span><br/>
          <span><img src={qr_code}></img></span><br/>
          <div>
            <form onSubmit={this.onAddCoin}>
              <div className="form-group">
                <button className="btn btn-sm btn-primary m-2" type="submit">Add Another</button>
                {error && <p className="text-danger">{error.message}</p>}
              </div>
            </form>
          </div>
        </div>
      )
    } else if (coin_id === "") {
      return (
        <div className="container">
          <span>New Coin: {product.description} </span><br/>
          <div>
            <form onSubmit={this.onAddCoin}>
              <div className="form-group">
              <label>Mint Year</label>&nbsp;
              <input
                  id="inputMintYear"
                  className="inputMintYear"
                  name="mint_year"
                  value={mint_year}
                  onChange={this.onChange}
                  placeholder="2022"
                />
              <br/>
              <label>Purchase Price</label>&nbsp;$
              <input
                  id="inputPurchasePrice"
                  className="inputPurchasePrice"
                  name="purchase_price"
                  value={purchase_price}
                  onChange={this.onChange}
                  placeholder="9.99"
                />
                <br/>
                <button className="btn btn-sm btn-primary m-2" type="submit">Add Coin</button>
                {error && <p className="text-danger">{error.message}</p>}
              </div>
            </form>
          </div>
        </div>
      )
    }
  }
}

export default withFirebase(CoinsAddPage);
