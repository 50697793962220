import React from "react";
import { compose } from "recompose";
import CoinsCaseList from "../Coins/CoinsCaseList";


import {
  AuthUserContext,
  withAuthorization,
} from "../Session";

const Dashboard = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div className="m-2">
          <CoinsCaseList userId={authUser.uid}/>
      </div>
    )}
  </AuthUserContext.Consumer>
);

const condition = (authUser) => !!authUser;

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(Dashboard);
