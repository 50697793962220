import React from "react";

const CoinsCaseBullion = (props) => (
    <p>
        <span className="font-weight-bold">Bullion Quote <small>as of {props.coin.time}</small></span>
        <br/>
        ${props.coin.silver_spot} per ounce silver spot
        <br/>
        ${props.coin.gold_spot} per ounce gold spot
        <br/>
        ${props.coin.platinum_spot} per ounce platinum spot
      </p>    
);

export default CoinsCaseBullion;
