import React from "react";
import { Route } from "react-router-dom";

import CoinsCaseList from "./CoinsCaseList";
import CoinsCaseProduct from "./CoinsCaseProduct";

import * as ROUTES from "../../constants/routes";

import {
  AuthUserContext,
  withAuthorization,
} from "../Session";

const CoinsCasePage = () => (
  <AuthUserContext.Consumer>
  {(authUser) => (
    <div>
        <Route exact path={ROUTES.COINS_CASE} render={(props) => (<CoinsCaseProduct authUser={authUser} {...props} />)} />
        <Route exact path={ROUTES.COINS_CASE_LIST} render={(props) => (<CoinsCaseList {...props} />)} />
    </div>
  )}
  </AuthUserContext.Consumer>
);



export default CoinsCasePage;
