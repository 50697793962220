import React from "react";
import { Route } from "react-router-dom";

import PlansDripList from "./PlansDripList";

import * as ROUTES from "../../constants/routes";

const PlansDripPage = () => (
  <div>
    <Route exact path={ROUTES.PLANS_DRIP} render={(props) => (<PlansDripList {...props} />)} />
    <Route exact path={ROUTES.PLANS_DRIP_LIST} render={(props) => (<PlansDripList {...props} />)} />
</div>
);

export default PlansDripPage;
