import React, { Component } from "react";

class PrivacyPage extends Component {

  render() {
    return (
      <div className="mx-2 mt-2 mb-2">
          <h3>Privacy Notice</h3>
          <p><h6 className="text-warning">How we use your personal information?</h6></p>
          <ul>
            <li>
                You can remove personal information by clicking "Remove My Account".
            </li>
            <li>
                We WILL share your public information with other customers but NOT share your private information.
            </li>
            <li>
                We will NOT share ANY information with external individuals or companies.
            </li>
            <li>
                We use your information collected from the website to personalize your repeat visits to the website.
            </li>
            <li>
                We collect information about you to process your order, manage your account, manage your listings, and post offers of other products that we offer.
            </li>
          </ul>          
          <p><h6 className="text-warning">Zenreki, LLC supports the following rights for individuals.</h6></p>
          <ul>
          <li>The right to be informed</li>
          <li>The right of access</li>
          <li>The right to rectification</li>
          <li>The right to erasure</li>
          <li>The right to restrict processing</li>
          <li>The right to data portability</li>
          <li>The right to object</li>
          </ul>          
      </div>
    );
  }
}

export default PrivacyPage;
