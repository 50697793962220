import React from "react";

const Header = () => (
  <header class="masthead masthead-full text-white">
    <div class="container h-100">
      <div class="row h-100 align-items-center">
        <div class="col text-center">
        <div class="masthead-subtitle text-primary">Securely buy, sell and store</div>
          <hr class="primary large"></hr>
          <div class="masthead-title text-uppercase">Bullion Coins</div>
        </div>
      </div>
    </div>
    <div class="masthead-scroll">
      <a href="#about" class="masthead-scroll-btn js-scroll-trigger">
        <i class="fas fa-angle-down"></i>
      </a>
    </div>
  </header>
);

export default Header;
