import React from "react";

const CoinsCaseCoin = (props) => (
    <p>
        <span className="font-weight-bold">Coin Value</span>
        <br />
        {props.coin.mint}&nbsp;{props.coin.mint_year}&nbsp;
        <a target="_blank" rel="noreferrer" href={props.coin.url}>
            {props.coin.description}
        </a>
        <br />
        <span>{props.coin.weight_oz} ounce ({props.coin.bullion_purity}%) {props.coin.bullion_type}</span>
        <br />
        <span> ${props.coin.melt_value} (spot) + ${props.coin.premium} (premium) = ${props.coin.coin_value}</span>
        <br />
        <span> Purchase price = ${props.coin.purchase_price}</span>
    </p>    
);

export default CoinsCaseCoin;
