import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
} from "../Session";
import { withFirebase } from "../Firebase";

import * as ROLES from "../../constants/roles";

const UserPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div className="m-2">
        <h3>User Settings</h3>
        <p><span className="mx-2"><b>Phone:</b> {authUser.phoneNumber}</span></p>
        <UserInfo roles={authUser.roles} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  roles: {},
  error: null,
};

class UserInfoBase extends Component {
  constructor(props) {
    super(props);
    INITIAL_STATE.roles = props.roles;
    this.state = { ...INITIAL_STATE };
  }

  render() {
    const {
      roles,
    } = this.state;

    if (roles[ROLES.INVESTOR]) {
      return (
        <div className="form-group mx-2">
          <a href="/Dashboard">
            <button className="btn btn-primary btn-sm">Go to your Dashboard</button>
          </a>
        </div>          
      );
    } else if (roles[ROLES.MEMBER]) {
        return (
        <div className="form-group mx-2">
          <p>As a <b>MEMBER</b>, your next step is to become an <b>INVESTOR</b>.</p>
          <p>This will allow you to send/receive money, and holds funds in a digital wallet.</p>
          <a href="/Investor">
            <button className="btn btn-primary btn-sm">Become an Investor</button>
          </a>
        </div>          
      );
    } else if (roles[ROLES.USER]) { 
      return (
        <div className="form-group mx-2">
          <p>As a <b>USER</b>, your next step is to become an <b>MEMBER</b>.</p>
          <p>This will allow you to receive money and desposit into an attached bank account.</p>
          <a href="/Member">
            <button className="btn btn-primary btn-sm">Go to Member</button>
          </a>
        </div>          
      );
    }
  }
}

const condition = (authUser) => !!authUser;

const UserInfo = withRouter(withFirebase(UserInfoBase));

export default compose(
  //withEmailVerification,
  withAuthorization(condition)
)(UserPage);
