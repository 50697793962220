import React, { Component } from "react";
import { Link } from "react-router-dom";

import PaypalCheckout from "../Paypal/PaypalCheckout";
import PlansDripAmount from "../Plans/PlansDripAmount";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const INITIAL_STATE = {
  loading: false,
  plans: [],
  limit: 20,
  orderBy: "weight_oz",
  showPaypal: false,
  orderTotal: 0,
  dripAmount: 1,
  frequency: "daily",
  monthlyTotal: 25,
};

class PlansDripList extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchDripPlans();
  }

  fetchDripPlans = async () => {
    this.setState({ plans: [], loading: true });

    const bullionType = this.props.match.params.bullionType;
    console.log("bullionType", bullionType);
    let plans = await this.props.firebase.fetchDripPlans(bullionType);
    plans.forEach(plan => this.calculateDripAmount(plan, plan.defaultValue) );

    this.setState({
        plans,
        loading: false,
    });
  }

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  }

  componentWillUnmount() {
  }
  
  calculateDripAmount = (plan, value) => {
    switch (plan.frequency) {
      case "daily":
        plan.monthlyTotal = value * 25;
        break;
      case "weekly":
        plan.monthlyTotal = value * 5;
        break;
      case "monthly":
        plan.monthlyTotal = value * 1;
        break;
      default: // adhoc
        break;
    }
    this.setState({
      update: true,
    });
  }

  showAmount = (dripAmount) => {
    this.setState({
      dripAmount,
    });
  }

  showPayment = (dripAmount) => {
    this.setState({
      showPaypal: true,
      dripAmount,
    });
  }

  closePayment = () => {
    this.setState({
      showPaypal: false,
    });
  }

  render() {
    const { monthlyTotal, showPaypal, orderTotal, plans, loading } = this.state;

    if (showPaypal) {
      return <PaypalCheckout orderTotal={orderTotal} closePayment={this.closePayment}/>
    } else {
      return (
        <div  id="PlansCaseList" className="tab-pane active">

          <h3 className="mx-4">Drip Plans</h3>
          {loading && <div>Loading ...</div>}
          {!loading && (plans.length === 0) && (
            <div>No plans found</div>
          )}
          {plans.map((plan) => (
            <dl className="mx-4 border-bottom" key={plan.uid}>
              <dt>
                <span>{plan.name}</span>
                &nbsp;
                <PlansDripAmount plan={plan} calculateDripAmount={this.calculateDripAmount}/>        
                &nbsp;
                <button 
                  className="btn btn-sm btn-primary" 
                  data-toggle="modal"
                  data-target={"#checkout-modal"}
                  onClick={() => this.showPayment(monthlyTotal)}>
                  Signup&nbsp;for&nbsp;${plan.monthlyTotal} Month
                </button>&nbsp;                              
                <Link
                    to={{
                      pathname: `${ROUTES.PLANS_DRIP}/${plan.uid}`,
                      state: { plan },
                    }}
                  >
                    Details
                </Link>
              </dt>
            </dl>
          ))}

        </div>
      );
    }
  }
}

export default withFirebase(PlansDripList);
