import React from "react";
import { NavLink } from "react-router-dom";

import { AuthUserContext } from "../Session";
import SignOutButton from "../SignOutButton";
import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import { FaBars } from 'react-icons/fa';

const Navigation = () => (
  <AuthUserContext.Consumer>
    {(authUser) =>
      authUser ? <NavigationAuth authUser={authUser} /> : <NavigationNonAuth />
    }
  </AuthUserContext.Consumer>
);

const HomeLogo = () => (
  <div>
    <a
      className="navbar-brand js-scroll-trigger"
      href="/"
    >
      <div className="col text-center">
        <img
          className="navbar-brand-logo"
          src="/img/switch_logo.png"
          alt="Switch Reward Card"
        ></img>
      </div>
    </a>
  </div>
);

const HamburgerMenu = () => (
  <button
    className="navbar-toggler"
    type="button"
    data-toggle="collapse"
    data-target="#navbarSupportedContent"
    aria-controls="navbarSupportedContent"
    aria-expanded="false"
    aria-label="Toggle navigation"
    style={{ color: "#ffbf00" }}
  >
    <FaBars />
  </button>
);

const NavigationNonAuth = () => (
  <nav
    className="navbar navbar-expand-lg navbar-dark fixed-top"
    id="navbar-main"
  >
    <div className="container">
      <HomeLogo />
      <HamburgerMenu />
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
        <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger active-nav"
              activeClassName="active-nav"
              to={ROUTES.HOME}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Home
            </NavLink>
          </li> 
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger active-nav"
              activeClassName="active-nav"
              to={ROUTES.COINS_CASE}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Coins
            </NavLink>
          </li> 
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger"
              activeClassName="active-nav"
              to={ROUTES.SIGN_UP}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Sign Up
            </NavLink>
          </li>          
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger"
              activeClassName="active-nav"
              to={ROUTES.SIGN_IN}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Sign In
            </NavLink>
          </li> 
        </ul>
      </div>
    </div>
  </nav>
);

const NavigationAuth = ({ authUser }) => (
  <nav
    className="navbar navbar-expand-lg navbar-dark fixed-top"
    id="navbar-main"
  >
    <div className="container">
      <HomeLogo />
      <HamburgerMenu />
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ml-auto">
          {!!authUser.roles[ROLES.ADMIN] && (
              <li className="nav-item">
                <NavLink
                  className="nav-link js-scroll-trigger"
                  activeClassName="active-nav"
                  to={ROUTES.ADMIN}
                  data-toggle="collapse"
                  data-target=".navbar-collapse"
                  >
                  Admin
                </NavLink>
              </li>
            )}
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger active-nav"
              activeClassName="active-nav"
              to={ROUTES.DASHBOARD}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Dashboard
            </NavLink>
          </li>          
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger active-nav"
              activeClassName="active-nav"
              to={ROUTES.ACCOUNT}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Account
            </NavLink>
          </li>          
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger"
              activeClassName="active-nav"
              to={ROUTES.COINS_CASE}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Coins
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink
              exact
              className="nav-link js-scroll-trigger"
              activeClassName="active-nav"
              to={ROUTES.PLANS_DRIP}
              data-toggle="collapse"
              data-target=".navbar-collapse"
            >
              Drip Plans
            </NavLink>
          </li>
          <SignOutButton />
        </ul>
      </div>
    </div>
  </nav>
);

export default Navigation;
