import React from "react";

const SignUpPage = () => (
  <div className="p-2">
    <h4>Sign Up</h4>
    <p>The sign up process starts with using your phone to sign in.</p>
    <a href="/Signin">
      <button className="btn btn-primary btn-sm">Sign in</button>
    </a>
    <div className="">
      <p className="mt-3">After sign in, you can upgrade to the following account types.</p>
      <div className="mx-3">
        <h6>Member</h6>
        <p>This will allow you to receive money and desposit into an attached bank account.</p>
        <h6>Investor</h6>
        <p>This will allow you to send/receive money, and holds funds in a digital wallet.</p>
      </div>
    </div>
  </div>
);

export default SignUpPage;
