import React from "react";

const CoinsCaseTotal = (props) => (
    <p>
        <span className="font-weight-bold">Total Value</span>
        <br />
        <span>Coin ${props.coin.coin_value} + Vault ${props.coin.cash_value} + Cash ${props.coin.cash_value} = </span><span className="font-weight-bold">${props.coin.total_value}
            &nbsp;&nbsp;<a href={'/Coins/Transfer/' + props.coin.uid} className="btn btn-sm btn-outline-primary">Transfer</a>            
        </span>
    </p>    
);

export default CoinsCaseTotal;
