import React, { Component } from "react";

class PlansDripAmount extends Component {
  constructor(props) {
    super(props);

    this.state = { ...props };
  }

  componentDidMount() {
  }

  calculateDripAmount = (plan) => {
    let value = document.getElementById("drip-amount-"+plan.uid).value;
    this.props.calculateDripAmount(plan, value);
  }

  componentWillUnmount() {
  }
  
  render() {
    const { plan } = this.state;

    if (plan.frequency === "daily") {
        return (        
            <select id={"drip-amount-"+plan.uid} class="form-select" aria-label="Default select example"
                onChange={() => this.calculateDripAmount(plan)}>
                <option selected value="1">$1</option>
                <option value="2">$2</option>
                <option value="4">$4</option>
                <option value="10">$10</option>
                <option value="20">$20</option>
                <option value="40">$40</option>
                <option value="100">$100</option>
            </select>
        );
    } 
    else if (plan.frequency === "weekly") {
        return (        
            <select id={"drip-amount-"+plan.uid} class="form-select" aria-label="Default select example"                   
                onChange={() => this.calculateDripAmount(plan)}>
                <option selected value="5">$5</option>
                <option value="10">$10</option>
                <option value="20">$20</option>
                <option value="50">$50</option>
                <option value="100">$100</option>
                <option value="200">$200</option>
                <option value="500">$500</option>
            </select>
           );
    } else if (plan.frequency === "monthly") {
        return (        
            <select id={"drip-amount-"+plan.uid} class="form-select" aria-label="Default select example"
                onChange={() => this.calculateDripAmount(plan)}>
                <option selected value="25">$25</option>
                <option value="50">$50</option>
                <option value="100">$100</option>
                <option value="250">$250</option>
                <option value="500">$500</option>
                <option value="1000">$1000</option>
                <option value="2500">$2500</option>
            </select>
        );
    } else {
        return ( 
            <span>bad plan frequency</span>
        );        
    }
  } 
}

export default PlansDripAmount;
