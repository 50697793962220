import React, { Component } from "react";

import { withFirebase } from "../Firebase";
import * as ROUTES from "../../constants/routes";

const INITIAL_STATE = {
  product: null,
  uid: "",
  name: "",
  description: "",
  bullion_purity: 0,
  bullion_type: "",
  buyback: 0,
  mint: "",
  image_front: "",
  premium: 0,
  url: "",
  weight_oz: 0,
  loading: false,
  error: null
};

class CoinsEditPage extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
    this.fetchCoin(this.props.match.params.id);
  }

  fetchCoin = async (uid) => {
    console.log("CoinEditPage fetchProduct", uid);
    let product = await this.props.firebase.fetchProduct(uid);
    if (product === null) {
      this.setState({
        loading: false,
      });
    } else {
      product.uid = uid;
      this.setState({
        product: product,
        uid: uid,
        name: product.name,
        description: product.description,
        bullion_purity: product.bullion_purity,
        bullion_type: product.bullion_type,
        buyback: product.buyback,
        mint: product.mint,
        image_front: product.image_front,
        premium: product.premium,
        url: product.url,
        weight_oz: product.weight_oz,
      });
    }
  }

  componentWillUnmount() {
  }

  onSaveCoin = (event) => {
    const { product, uid, name, bullion_purity, bullion_type, description, buyback, mint, image_front, premium, url, weight_oz, loading, error } = this.state;
    console.log("product", product);
    this.props.firebase.saveCoinProduct(uid, name, bullion_purity, bullion_type, description, buyback, mint, image_front, premium, url, weight_oz);
    this.props.history.push(ROUTES.COINS_CASE);
    event.preventDefault();
  };    

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  
  render() {
    const { product, name, description, bullion_purity, bullion_type, buyback, mint, image_front, premium, url, weight_oz, loading, error } = this.state;

    if (loading) {
      return (
        <div className="container">Loading...</div>
      )
    } else if (product != null) {
      return (
        <div className="container">
          <h4>Coin Product</h4>
          <div>
            <form onSubmit={this.onSaveCoin}>
              <div className="form-group">
                <label htmlFor="inputName">Name</label>&nbsp;
                <input
                  id="inputName"
                  className="inputName"
                  name="name"
                  value={name}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputDescription">Description</label>&nbsp;
                <input
                  id="inputDescription"
                  className="inputDescription"
                  name="description"
                  value={description}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputbullion_type">Bullion Type</label>&nbsp;
                <input
                  id="inputbullion_type"
                  className="inputbullion_type"
                  name="bullion_type"
                  value={bullion_type}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputbullion_purity">Bullion Purity</label>&nbsp;
                <input
                  id="inputbullion_purity"
                  className="inputbullion_purity"
                  name="bullion_purity"
                  value={bullion_purity}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputbuyback">Buyback</label>&nbsp;
                <input
                  id="inputbuyback"
                  className="inputbuyback"
                  name="buyback"
                  value={buyback}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputmint">Mint</label>&nbsp;
                <input
                  id="inputmint"
                  className="inputmint"
                  name="mint"
                  value={mint}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputimage_front">Image Front</label>&nbsp;
                <input
                  id="inputimage_front"
                  className="inputimage_front"
                  name="image_front"
                  value={image_front}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputpremium">Premium</label>&nbsp;
                <input
                  id="inputpremium"
                  className="inputpremium"
                  name="premium"
                  value={premium}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputurl">URL</label>&nbsp;
                <input
                  id="inputurl"
                  className="inputurl"
                  name="url"
                  value={url}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <label htmlFor="inputweight_oz">Weight (oz.)</label>&nbsp;
                <input
                  id="inputweight_oz"
                  className="inputweight_oz"
                  name="weight_oz"
                  value={weight_oz}
                  onChange={this.onChange}
                  placeholder=""
                /><br/>
                <button className="btn btn-sm btn-primary m-2" type="submit">Save Changes</button>
                {error && <p className="text-danger">{error.message}</p>}
              </div>
            </form>
          </div>
        </div>
      )
    }
     else {
      return (
        <div className="container">
          <span>Looking for product...</span><br/>
        </div>
      )
    }
  }
}

export default withFirebase(CoinsEditPage);
