import React, { Component } from "react";

import { PayPalButtons } from "@paypal/react-paypal-js";

const INITIAL_STATE = {
  loading: false,
};

class PaypalCheckout extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  componentDidMount() {
  }
  
  render() {
    return (
      <div className="mx-4">
        <h3>Select a payment method</h3>
        
        <PayPalButtons
          createOrder={(data, actions) => {
              return actions.order.create({
                  purchase_units: [
                      {
                          amount: {
                              value: this.props.orderTotal,
                          },
                      },
                  ],
              });
          }}
          onApprove={(data, actions) => {
              return actions.order.capture().then((details) => {
                this.props.closePayment();                 
              });
          }}
        />                
      </div>
    );
  }
}

export default PaypalCheckout;
