import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";
import * as GLOBALS from "../../constants/globals";

const MemberInfo = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <h3>Member Settings</h3>
        <p><span className="mx-2"><b>Name:</b> {authUser.firstname}&nbsp;{authUser.lastname}</span></p>
        <p><span className="mx-2"><b>Email:</b> {authUser.email}</span></p>
      </div>
    )}
    </AuthUserContext.Consumer>
  );

const MemberPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div className="m-2">
        <MemberJoinForm roles={authUser.roles} firstname={authUser.firstname} lastname={authUser.lastname} email={authUser.email} />
        <br/>
      </div>
    )}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  firstname: "",
  lastname: "",
  email: "",
  passwordOne: "",
  passwordTwo: "",
  roles: {},
  error: null,
};

class MemberJoinFormBase extends Component {
  constructor(props) {
    super(props);
    INITIAL_STATE.roles = props.roles;
    INITIAL_STATE.firstname = props.firstname;
    INITIAL_STATE.lastname = props.lastname;
    INITIAL_STATE.email = props.email;    
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {
    // add user to Firebase Google auth, Firebase DB, and Dwolla
    const { firstname, lastname, email, passwordOne, roles } = this.state;

    // set the Firebase Google auth email
    this.props.firebase.auth.currentUser.updateEmail(email) // email must not already exist or it will throw an error
    .then(() => {
      // udpate Firebase DB
      roles[ROLES.MEMBER] = ROLES.MEMBER;
      this.props.firebase.user(this.props.firebase.auth.currentUser.uid).set(
      {
        firstname,
        lastname,
        email,
        roles,
      }, {merge: true})
      .then(() => {
        this.props.firebase.auth.currentUser.sendEmailVerification({ url: "https://zenreki.cash/Dashboard" });
        
        // update Google auth with username/password
        this.props.firebase.auth.currentUser.updatePassword(passwordOne) // must have signed in recently or it will throw an error
        .then(() => {
          // add user to DWOLLA
          var url = "https://us-central1-zenreki-cash.cloudfunctions.net/addDwollaUser?uid=" + encodeURI(this.props.firebase.auth.currentUser.uid) + 
          "&firstname=" + encodeURI(firstname) + 
          "&lastname=" + encodeURI(lastname) + 
          "&email=" + encodeURI(email);
          fetch(url, { mode: 'no-cors'})                
            .then(() => {
              console.log("member upgrade success");
              this.props.history.push(ROUTES.DASHBOARD);
            }) // set firebase database user fields
            .catch((error) => {
              console.log("failed to addDwollaUser", error);
              this.setState({ error });
            });
        }) // set firebase database user fields
        .catch((error) => {
          console.log("failed to update DB user ", error);
          this.setState({ error });
        })              
      }) // update firebase google auth password
      .catch((error) => {
        console.log("failed to update Google auth password ", error);
        if (error.code === GLOBALS.ERROR_CODE_RECENT_LOGIN) {
          error.message = GLOBALS.ERROR_MSG_RECENT_LOGIN;
        }
        this.setState({ error });
      })     
    }) // update firebase google auth email
    .catch((error) => {
      console.log("failed to update Google auth email", error);
      if (error.code === GLOBALS.ERROR_CODE_ACCOUNT_EXISTS) {
        error.message = GLOBALS.ERROR_MSG_ACCOUNT_EXISTS;
      }
      this.setState({ error });
    });

    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const {
      firstname,
      lastname,
      email,
      passwordOne,
      passwordTwo,
      roles,
      error,
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === "" ||
      email === "" ||
      firstname === "" ||
      lastname === "";

    if (roles[ROLES.INVESTOR]) {
      return (
        <div className="form-group mx-2">
          <MemberInfo />
          <a href="/Dashboard">
            <button className="btn btn-primary btn-sm">Go to your Dashboard</button>
          </a>
        </div>          
      );
    } else if (roles[ROLES.MEMBER]) { // only a MEMBER, need to become an INVESTOR first
      return (
        <div className="form-group mx-2">
          <MemberInfo />
          <h6>Become a MEMBER</h6>
          <p>This will allow you to receive money and desposit into an attached bank account.</p>
          <a href="/Investor">
            <button className="btn btn-primary btn-sm">Become an Investor</button>
          </a>
        </div>          
      );
    } else { // only a USER, need to become a MEMBER
      return (
        <form onSubmit={this.onSubmit}>
          <div className="form-group p-1 mx-2">
          <h6>Become a MEMBER</h6>
          <p>This will allow you to receive money and desposit into an attached bank account.<br/>Financial regulations require your legal First and Last name.</p>
          <label htmlFor="inputUserFirstname">Legal First Name</label>
            <input
              id="inputUserFirstname"
              className="form-control mb-2"
              name="firstname"
              value={firstname}
              onChange={this.onChange}
              type="text"
              placeholder="Legal First Name"
            />
            <label htmlFor="inputLastname">Legal Last Name</label>
            <input
              id="inputUserLastname"
              className="form-control mb-2"
              name="lastname"
              value={lastname}
              onChange={this.onChange}
              type="text"
              placeholder="Legal Last Name"
            />
            <label htmlFor="inputEmail">Email</label>
            <input
              id="inputEmail"
              className="form-control mb-2"
              name="email"
              value={email}
              onChange={this.onChange}
              type="text"
              placeholder="Email"
            />
            <label htmlFor="inputPassword">Password</label>
            <input
              id="inputPassword"
              className="form-control mb-2"
              name="passwordOne"
              value={passwordOne}
              onChange={this.onChange}
              type="password"
              placeholder="Password"
            />
            <label htmlFor="inputConfirmPassword">Confirm Password</label>
            <input
              id="inputConfirmPassword"
              className="form-control mb-2"
              name="passwordTwo"
              value={passwordTwo}
              onChange={this.onChange}
              type="password"
              placeholder="Confirm Password"
            />
            <br/>
            <button
              className="mt-2 btn btn-primary btn-lg"
              disabled={isInvalid}
              type="submit"
            >
              Become a Member
            </button>
            <br/><br/>
            {error && <p className="text-danger">{error.message}</p>}
          </div>
        </form>
      );
    }
  }
}

const condition = (authUser) => !!authUser;

const MemberJoinForm = withRouter(withFirebase(MemberJoinFormBase));

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(MemberPage);
