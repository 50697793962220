import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";

import {
  AuthUserContext,
  withAuthorization,
  withEmailVerification,
} from "../Session";
import { withFirebase } from "../Firebase";

import * as ROUTES from "../../constants/routes";
import * as ROLES from "../../constants/roles";

const InvestorInfo = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div>
        <h3>Investor Settings</h3>
        <div className="my-3">
          <p><span className="mx-2"><b>Date of birth:</b> {authUser.dateOfBirth}</span></p>
          <p><span className="mx-2"><b>Social Security Number:</b> ***{authUser.lastFour}</span></p>
          <h6>Mailing Address</h6>
          <span className="mx-2">{authUser.address1}&nbsp;{authUser.address2}</span><br/>
          <span className="mx-2">{authUser.city},&nbsp;{authUser.state}&nbsp;{authUser.postalCode}</span><br/>
        </div>
      </div>
    )}
    </AuthUserContext.Consumer>
);

const InvestorPage = () => (
  <AuthUserContext.Consumer>
    {(authUser) => (
      <div className="m-2">
        <InvestorJoinForm roles={authUser.roles} />
      </div>
    )}
  </AuthUserContext.Consumer>
);

const INITIAL_STATE = {
  dateOfBirth: "",
  ssn1: "",
  ssn2: "",
  address1: "",
  city: "",
  state: "",
  postalCode: "",
  roles: {},
  error: null,
};

class InvestorJoinFormBase extends Component {
  constructor(props) {
    super(props);
    INITIAL_STATE.roles = props.roles;
    this.state = { ...INITIAL_STATE };
  }

  onSubmit = (event) => {

    const { roles, dateOfBirth, ssn1, address1, address2, city, state, postalCode } = this.state;
    roles[ROLES.INVESTOR] = ROLES.INVESTOR;

    // get the last four of the SSN
    const lastFour = ssn1.substring(5)

    // CNH: TODO - prevent duplicate "ssn" by searching Firebase DB

    // upgrade MEMBER to INVESTOR in firebase DB and Dwolla
    var phone = this.props.firebase.auth.currentUser.phoneNumber.substring(2); // strip the "+1" character
    var url = "https://us-central1-zenreki-cash.cloudfunctions.net/upgradeDwollaUser?uid=" + encodeURI(this.props.firebase.auth.currentUser.uid)  + 
    "&firstName=" + encodeURI(this.props.firebase.auth.currentUser.firstname) + 
    "&lastName=" + encodeURI(this.props.firebase.auth.currentUser.lastname) + 
    "&email=" + encodeURI(this.props.firebase.auth.currentUser.email) +
    "&address1" + encodeURI(address1) +
    "&address2" + encodeURI(address2) +
    "&city" + encodeURI(city) +
    "&state" + encodeURI(state) +
    "&postalCode" + encodeURI(postalCode) +
    "&dateOfBirth" + encodeURI(dateOfBirth) +
    "&lastFour" + encodeURI(lastFour) +
    "&phone" + encodeURI(phone) +
    fetch(url, { mode: 'no-cors'}).then(() => {
      this.props.firebase.user(this.props.firebase.auth.currentUser.uid).set(
      {
        address1,
        address2: (address2 != null) ? address2 : "",
        dateOfBirth,
        ssn: ssn1,
        lastFour,
        city,
        state,
        postalCode,
        roles,
      }, {merge: true})
      .then(() => {
        console.log("investor upgrade success");
        this.props.history.push(ROUTES.DASHBOARD);
      }) // set firebase database user fields
      .catch((error) => {
        console.log("failed to update DB user ", error);
      })              
    }) // addDwollaUser
    .catch((error) => {
      console.log("failed to addDwollaUser", error);
    });
        
    event.preventDefault();
  };

  onChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onChangeCheckbox = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const {
      dateOfBirth,
      ssn1,
      ssn2,
      address1,
      address2,
      city,
      state,
      postalCode,
      roles,
      error,
    } = this.state;

    const isInvalid =
      ssn1 !== ssn2 ||
      ssn1 === "" ||
      dateOfBirth === "" ||
      address1 === "" ||
      city === "" ||
      state === "" ||
      postalCode === "";

    if (roles[ROLES.INVESTOR]) { 
      return (
        <div className="form-group mx-2">
          <InvestorInfo />
          <a href="/Dashboard">
            <button className="btn btn-primary btn-sm">Go to your Dashboard</button>
          </a>
        </div>          
      );
    } else if (roles[ROLES.MEMBER]) { 
      return (
        <form onSubmit={this.onSubmit}>
          <div className="form-group p-1 mx-2">
            <h6>Become an INVESTOR <small>(duplicate accounts are NOT allowed)</small></h6>
            <p>This will allow you to send/receive money, and holds funds in a digital wallet.<br/><br/>Financial regulations require your address and identity verification. You may be required to submit additional documentation (e.g. drivers license).</p>
            <label htmlFor="inputStreet">Street</label>
            <input
              id="inputStreet"
              className="form-control mb-2"
              name="address1"
              value={address1}
              onChange={this.onChange}
              type="text"
              placeholder="Street"
            />
            <label htmlFor="inputLine2">Street 2nd Line</label>
            <input
              id="inputLine2"
              className="form-control mb-2"
              name="address2"
              value={address2}
              onChange={this.onChange}
              type="text"
              placeholder="Street 2nd Line"
            />
            <label htmlFor="inputCity">City</label>
            <input
              id="inputCity"
              className="form-control mb-2"
              name="city"
              value={city}
              onChange={this.onChange}
              type="text"
              placeholder="City"
            />
            <label htmlFor="inputState">State</label>
            <select
              id="inputState"
              className="form-control mb-2"
              name="state"
              value={state}
              onChange={this.onChange}
              type="text"
              placeholder="State"
            >
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AZ">Arizona</option>
              <option value="AR">Arkansas</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DE">Delaware</option>
              <option value="DC">District Of Columbia</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <label htmlFor="inputPostalCode">Postal Code</label>
            <input
              id="inputPostalCode"
              className="form-control mb-2"
              name="postalCode"
              value={postalCode}
              onChange={this.onChange}
              type="text"
              placeholder="Postal Code"
            />
            <label htmlFor="inputDOB">Date of Birth</label>
            <input
              id="inputDOB"
              className="form-control mb-2"
              name="dateOfBirth"
              value={dateOfBirth}
              onChange={this.onChange}
              type="date"
              placeholder="Date of Birth"
            />
            <label htmlFor="inputSSN">US Social Security Number <small>(required by US banking laws)</small></label>
            <input
              id="inputSSN"
              className="form-control mb-2"
              name="ssn1"
              value={ssn1}
              onChange={this.onChange}
              pattern="[0-9]{9,9}"
              minLength="9"
              maxLength="9"
              type="password"
              placeholder="US Social Security Number"
            />
            <label htmlFor="inputSSN">Confirm US Social Security Number</label>
            <input
              id="inputSSN"
              className="form-control mb-2"
              name="ssn2"
              value={ssn2}
              onChange={this.onChange}
              pattern="[0-9]{9,9}"
              minLength="9"
              maxLength="9"
              type="password"
              placeholder="Confirm US Social Security Number"
            />
            <br/>
            <button
              className="mt-2 btn btn-primary btn-lg"
              disabled={isInvalid}
              type="submit"
            >
              Become an Investor
            </button>
            <br/><br/>
            {error && <p className="text-danger">{error.message}</p>}
          </div>
        </form>
      );
    } else { // only a USER, need to become a MEMBER first
      return (
        <div className="form-group mx-2">
          <h6>Become a MEMBER</h6>
          <p>This will allow you to send/receive money, and holds funds in a digital wallet.</p>
          <a href="/Member">
            <button className="btn btn-primary btn-sm">Go to Member</button>
          </a>
        </div>          
      );
    }
  }
}

const condition = (authUser) => !!authUser;

const InvestorJoinForm = withRouter(withFirebase(InvestorJoinFormBase));

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(InvestorPage);
