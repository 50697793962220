import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

import * as ROLES from "../../constants/roles";

const config = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_ID,
  appMeasurementId: process.env.REACT_APP_MEASUREMENT_ID
};

class FirebaseDatabase {
  constructor() {

    console.log("init cash firebase");
    firebase.initializeApp(config);

    /* Helper */

    this.fieldValue = firebase.firestore.FieldValue;
    
    /* Firebase APIs */

    this.auth = firebase.auth();
    this.db = firebase.firestore();
    
    /* Social Sign In Method Provider */

    this.googleProvider = new firebase.auth.GoogleAuthProvider();
    this.facebookProvider = new firebase.auth.FacebookAuthProvider();
    this.twitterProvider = new firebase.auth.TwitterAuthProvider();
    this.phoneAuthProvider = firebase.auth.PhoneAuthProvider;
    this.emailAuthProvider = firebase.auth.EmailAuthProvider;
    this.phoneMultiFactor = firebase.auth.PhoneMultiFactorGenerator;

    // Configure FirebaseUI.
    this.uiSignInConfig = {
      // Popup signin flow rather than redirect flow.
      signInFlow: 'popup',
      // Redirect to /signedIn after sign in is successful. Alternatively you can provide a callbacks.signInSuccess function.
      signInSuccessUrl: '/',
      // We will display Google and Facebook as auth providers.
      signInOptions: [
        firebase.auth.PhoneAuthProvider.PROVIDER_ID,
      ],
      callbacks: {
        signInSuccessWithAuthResult: function(authResult, redirectUrl) {
          console.log("sign IN success", authResult);
          //var user = authResult.user;
          //var credential = authResult.credential;
          var isNewUser = authResult.additionalUserInfo.isNewUser;
          //var providerId = authResult.additionalUserInfo.providerId;
          //var operationType = authResult.operationType;        
          if (isNewUser) {
          }  
          return true;
        }
      }
    };

  }

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
  this.auth.onAuthStateChanged(authUser => {
    if (authUser) {
      this.user(authUser.uid).get()
        .then(doc => {
          const user = doc.data();
          if (user == null) {
            console.log("user not found, creating...");
            // create a welcome message

            // create a welcome post

            // Create a user in your Firebase database
            const roles = {}
            roles[ROLES.USER] = ROLES.USER;
            this.user(authUser.uid).set(
              {
                uid: authUser.uid,
                email: "",
                roles: roles,
                phoneNumber: authUser.phoneNumber,
              },
              { merge: true }
            )
            .then(() => {
              this.user(authUser.uid).get()
              .then(snapshot => {
                const newUser = snapshot.data();
                if (newUser != null) {

                  // default empty roles
                  if (!user.roles) {
                    user.roles = {};
                  }

                  // merge auth and DB user
                  authUser = {
                    emailVerified: authUser.emailVerified,
                    providerData: authUser.providerData,
                    ...user
                  };
                  next(authUser);
                }
              });
            });
          } else { // user is NOT NULL

            // default empty roles
            if (!user.roles) {
              user.roles = {};
            }

            // merge auth and DB user
            authUser = {
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...user
            };
            authUser.ssn = null; //  hide ssn
            next(authUser);
          }
        });
    } else { 
      // authUser is NULL      
      console.log("user logged out");  
      fallback();
    }
  });

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignInWithGoogle = () => this.auth.signInWithPopup(this.googleProvider);

  doSignInWithFacebook = () => this.auth.signInWithPopup(this.facebookProvider);

  doSignInWithTwitter = () => this.auth.signInWithPopup(this.twitterProvider);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification({
      url: "https://zenreki.cash/Dashboard"
    });

  doPasswordUpdate = password => this.auth.currentUser.updatePassword(password);

  deleteMessages = (userId) => 
    this.db.collection("messages").where('userId', '==', userId).get()
    .then(function(querySnapshot) {
      var batch = firebase.firestore().batch();
      querySnapshot.forEach(function(doc) {
        batch.delete(doc.ref);
      });
      return batch.commit();
    });

  // *** COIN API ***

  addCoin = async (product, coin_product, mint_year, purchase_price) => {

    console.log("coin_product", coin_product);
    console.log("mint_year", mint_year);
    console.log("purchase_price", purchase_price);

    let coin_id = null;
    if ((product != null) && (coin_product != "") && (mint_year != "") && (purchase_price != "")) {
      this.db.collection("coins").add({
        bullion_purity: product.bullion_purity,
        bullion_type: product.bullion_type,
        cash_value: 0,
        coin_product: coin_product,
        description: product.description,
        image_front: product.image_front,
        mint: product.mint,
        mint_year: mint_year,
        nfc_id: "",
        owner: "lLfj5r73fZRROfYmC0L7smNBaxG2",
        premium: product.premium,
        printed: false,        
        purchase_price: parseFloat(purchase_price),
        url: product.url,
        weight_oz: product.weight_oz,
      })
      .then((docRef) => {
        coin_id = docRef.id;
        console.log('new coin', coin_id);
      })
      .catch(function (error) {
          console.log("error" + error);
      });

    } 
    else {
      console.log("product, coin_product or mint_year are null");
    }

    return coin_id;

  }
    
  fetchCoinTypes = async (coin_type) => {
    let resultsPromise = [];
    let docList = null;
    docList = await this.db.collection(coin_type).get();
    for(const doc of docList.docs) {
      resultsPromise.push({ ...doc.data(), uid: doc.id });
    }
    return resultsPromise;
  } 

  assignOwner = async (oldOwnerId, coinId, newOwnerId) => {
    let coin = null;
    let docCoin = await this.db.collection("coins").doc(coinId).get();
    if (docCoin.exists) {
      coin = docCoin.data();
      if (coin.owner != null) {
        if (coin.owner === oldOwnerId) {
          // assign the new owner
          this.db.collection("coins").doc(coinId).set(
            {
              owner: newOwnerId
            },
            { merge: true }
          );
        } else {
          console.log("Owner did not match!!!");
        }
      } else {
        console.log("Current owner not set");
      }
    } else {
      console.log("Coin not found!!!")
    }
  }
      
  createCoinProduct = async (uid, name, bullion_purity, bullion_type, description, buyback, mint, image_front, premium, url, weight_oz) => {
    // assign the new owner
    this.db.collection("coin_product").doc(uid).set(
      {
        bullion_purity: parseFloat(bullion_purity),
        bullion_type: bullion_type,
        buyback: parseFloat(buyback),
        description: description,
        image_front: image_front,
        mint: mint,
        name: name,
        premium: parseFloat(premium),
        url: url,
        weight_oz: parseFloat(weight_oz),
      },
      { merge: true }
    );
  } 

  saveCoinProduct = async (uid, name, bullion_purity, bullion_type, description, buyback, mint, image_front, premium, url, weight_oz) => {
    // assign the new owner
    this.db.collection("coin_product").doc(uid).set(
      {
        bullion_purity: parseFloat(bullion_purity),
        bullion_type: bullion_type,
        buyback: parseFloat(buyback),
        description: description,
        image_front: image_front,
        mint: mint,
        name: name,
        premium: parseFloat(premium),
        url: url,
        weight_oz: parseFloat(weight_oz),
      },
      { merge: true }
    );
  } 

  fetchUser = async (phoneNumber) => {
    let phone = "+" + phoneNumber;
    console.log("Searching for user", phone);
    let resultsPromise = [];
    let docList = null;
    docList = await this.db.collection("users")
      .where("phoneNumber", "==", phone)
      .get();    
      for(const doc of docList.docs) {
        resultsPromise.push({ ...doc.data(), uid: doc.id });
      }
      return resultsPromise;
    }

  fetchProduct = async (uid) => {
    console.log("fetchProduct");
    let coin = null;
    let docCoin = await this.db.collection("coin_product").doc(uid).get();
    if (docCoin.exists) {
      coin = docCoin.data();
      console.log("product", coin);
    } else {
      console.log("Product not found!!!")
    }
    return coin;
  }

  fetchCoin = async (uid) => {
    console.log("fetchCoin");
    let coin = null;
    let docCoin = await this.db.collection("coins").doc(uid).get();
    if (docCoin.exists) {
      coin = docCoin.data();
    } else {
      console.log("Coin not found!!!")
    }
    return coin;
  }

  fetchCoinWithOwner = async (uid) => {
    console.log("fetchCoinWithOwner");
    let coin = null;
    let docCoin = await this.db.collection("coins").doc(uid).get();
    if (docCoin.exists) {
      coin = docCoin.data();
      if (coin.owner != null) {
        let docOwner = await this.db.collection("users").doc(coin.owner).get();
        if (docOwner.exists) {
          coin.owner = docOwner.data();
        } else {
          console.log("Owner not found!!!");
        }
      } else {
        console.log("Owner not set");
      }
    } else {
      console.log("Coin not found!!!")
    }
    return coin;
  }

  fetchNft = async (address, orderby, limit) => {
    console.log("fetchCoins");
    let resultsPromise = [];

    return resultsPromise;
  }

  fetchCoins = async (ownerId, orderby, limit) => {
    console.log("fetchCoins");
    let resultsPromise = [];
    let coinsList = await this.db.collection("coins")
      .where("owner", "==", ownerId)
      .orderBy(orderby)
      .limit(limit)
      .get();
    for(const coinDoc of coinsList.docs) {
      let coin = await this.coin(coinDoc.id).get();
      resultsPromise.push({ ...coin.data(), uid: coin.id });
    }
    return resultsPromise;
  }

  fetchDripPlans = async (bullion_type) => {
    let resultsPromise = [];
    let plansList = null;
    if (bullion_type == null) {
      plansList = await this.db.collection("drip").get();
    } else {
      plansList = await this.db.collection("drip")
      .where("bullion_type", "==", bullion_type)
      .get();
    }
    for(const planDoc of plansList.docs) {
      resultsPromise.push({ ...planDoc.data(), uid: planDoc.id });
    }
    return resultsPromise;
  }

  coin = uid => this.db
    .doc(`coins/${uid}`);

  coins = () => this.db
    .collection("coins");

  coin_product = () => this.db
    .collection("coin_product");

  user = uid => this.db
    .doc(`users/${uid}`);

  users = () => this.db
    .collection("users");
}

export default FirebaseDatabase;
