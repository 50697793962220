export const LOADING_IMAGE_URL = 'https://www.google.com/images/spin-32.gif?a';
export const MESSAGE_BUSINESS = "message-business";
export const MESSAGE_GROUP = "message-group";
export const MESSAGE_USER = "message-user";
export const POST_BUSINESS = "post-business";
export const POST_GROUP = "post-group";
export const POST_USER = "post-user";
export const MESSAGE_SYSTEM = "message-system";
export const MESSAGE_SENDER = "message-sender";
export const MESSAGE_OTHER = "message-other";

export const Infura_API_Key = "zenreki-cash";
export const Infura_API_Key_Secret = "770e1bd071e64d2d991b62bc6ebf4dff";
export const Infura_Wallet_Public_Key = "0xE863468E73489DfB7Bb4fa22d815265Fce3876bd";
export const Infura_Wallet_Private_Key = "a6999b4150c91985d3138e0bc49b9a9f2877ff5650b1cf22c81e5bf2041ca57c";
export const Infura_ETH_URL = "https://mainnet.infura.io/v3/a267159b5a144c559ecab87ef2d2f020";

export const ENVIRONMENT = {
    sandbox: "https://api-sandbox.dwolla.com",
    production: "https://api.dwolla.com",
  };

export const port = 4041;
export const env = "sandbox";

export const ERROR_CODE_ACCOUNT_EXISTS = "auth/email-already-in-use";

export const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with this email address already exists.
  Try to login with this account. If you think the
  account is already used from one of the social logins, try
  to sign in with one of them. Afterward, associate your accounts
  on your personal account page.
`;

export const ERROR_CODE_RECENT_LOGIN = "auth/requires-recent-login";

export const ERROR_MSG_RECENT_LOGIN = `
  Your authentication has expired, please Sign Out then Sign In again and retry Becoming a Member.
`;
