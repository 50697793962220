import React from "react";

import { withFirebase } from "../Firebase";

const SignOutButton = ({ firebase }) => (
  <li className="nav-item">
    <a className="nav-link js-scroll-trigger" href="/Signin" onClick={firebase.doSignOut} data-toggle="collapse" data-target=".navbar-collapse">
      Sign Out
    </a>
  </li>
);

export default withFirebase(SignOutButton);
